import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectwidgets } from "../../../features/widgets/selector";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch, Button, Form, Input, notification, Checkbox } from "antd";

import REQUESTS from "../../../api/requests";

import "../style/googleIMA.scss";

export default function GoogleIMA() {
  const [imaConfigs, setImaConfigs] = useState(null);
  const [tagUrl, setTagUrl] = useState(null);
  const [checkedLive, setCheckedLive] = useState();
  const [packages, setPackages] = useState([]);
  const [currency, setCurrency] = useState();
  const [number, setNumber] = useState("");

  const [packagesIds, setPackagesIds] = useState([]);
  const [checkedPackage, setCheckedPackage] = useState([]);

  const widgets = useSelector(selectwidgets);

  const [form] = Form.useForm();

  const onChange = (checked) => {
    setImaConfigs({ ...imaConfigs, is_enable: checked });
  };

  const handleTestUrl = () => {
    if (tagUrl) {
      const url = `http://ads_test.tvplayers.net/?tag=${tagUrl}&name=custom&type=custom`;
      window.open(url, "_blank");
    }
  };

  const getIMAConfig = () => {
    function callback(data) {
      setImaConfigs(data);
      setTagUrl(data.tag_url);
      setCheckedLive(data.live_tv);
    }
    REQUESTS.IMA_CONFIG.GET(callback);
  };

  const getPackages = () => {
    function callback(data) {
      setPackages(data.rows);
      const newData = data.rows
        .filter((item) => item.has_ads)
        .map((item) => item.id);

      setPackagesIds([...packagesIds, ...newData]);
    }

    REQUESTS.USER_PACKEGES.GET({}, callback);
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => setCurrency(data.currency));
  };

  const onValuesChange = (changedValues, allValues) => {
    if (Object.keys(changedValues)[0] === "tag_url") {
      setTagUrl(changedValues.tag_url);
    } else if (Object.keys(changedValues)[0] === "live_tv") {
      setCheckedLive(changedValues.live_tv);
    }
  };

  const onFinish = (values) => {
    const body = {
      tag_url: values.tag_url,
      live_tv: values.live_tv,
      movie: values.movie,
      tv_show: values.tv_show,
      live_show_interval: Number(number),
      is_enable: values.is_enable,
      package_ids: packagesIds,
    };

    function callback(message) {
      getIMAConfig();
      notification.open({
        message: "Google IMA Settings",
        description: message,
        duration: 5,
      });
    }
    function errorCallback(err) {
      notification.error({
        message: "Google IMA Settings",
        description: err,
        duration: 5,
      });
    }

    REQUESTS.IMA_CONFIG.PUT(body, callback, errorCallback);
  };

  useEffect(() => {
    getIMAConfig();
    getPackages();
    getPaymentSettings();
  }, []);

  useEffect(() => {
    if (checkedPackage) {
      if (checkedPackage.checked) {
        setPackagesIds([...packagesIds, checkedPackage.id]);
      } else {
        const newpackagesIds = packagesIds.filter(
          (id) => id !== checkedPackage.id
        );
        setPackagesIds([...packagesIds, ...newpackagesIds]);
      }
    }
  }, [checkedPackage]);

  useEffect(() => {
    if (imaConfigs) {
      setNumber(imaConfigs.live_show_interval);
      form.setFields([
        { name: "tag_url", value: imaConfigs.tag_url },
        { name: "is_enable", value: imaConfigs.is_enable },
        { name: "live_tv", value: imaConfigs.live_tv },
        { name: "movie", value: imaConfigs.movie },
        { name: "tv_show", value: imaConfigs.tv_show },
      ]);
    }
  }, [imaConfigs]);

  return (
    <div>
      {widgets &&
        widgets.google_ima &&
        widgets.google_ima.enabled &&
        widgets.google_ima.enabled === "false" && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}

      <h3 className="google-ima-title">Google IMA Settings</h3>
      <Form
        layout={"vertical"}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        form={form}
        style={{
          maxWidth: 500,
        }}
      >
        <div className="google-ima-form">
          <Form.Item valuePropName="checked" name={"is_enable"}>
            Enable IMA ADS{" "}
            <Switch
              checked={imaConfigs && imaConfigs.is_enable}
              onChange={onChange}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
        <div className="google-ima_tag-url-container">
          <Form.Item
            name="tag_url"
            label="Enter tag url"
            rules={[
              {
                required: true,
                message: "Url is required",
              },
              {
                type: "url",
                message: "Please enter valid url.",
                warningOnly: true,
              },
            ]}
            className="google-ima_tag-url-container_url-inp"
          >
            <Input disabled={imaConfigs && !imaConfigs.is_enable} />
          </Form.Item>
          <div className="google-ima_tag-url-container_test-url-btn">
            <Button
              type="primary"
              onClick={handleTestUrl}
              disabled={imaConfigs && !imaConfigs.is_enable}
            >
              TEST URL
            </Button>
          </div>
        </div>

        <Form.Item name="live_tv" valuePropName="checked" noStyle>
          <Checkbox
            style={{ marginBottom: 5 }}
            checked={checkedLive}
            disabled={imaConfigs && !imaConfigs.is_enable}
          >
            Enable ads for Live TV
          </Checkbox>
        </Form.Item>
        <div>
          <div>Ads show interval</div>
          <p> Interval must be greater than or equal to 1</p>
          <input
            type="number"
            name="live_show_interval"
            min="1"
            step="1"
            defaultValue={imaConfigs && imaConfigs.live_show_interval}
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="google-ima_number-inp"
            disabled={!checkedLive}
          />
          Seconds
        </div>
        <Form.Item name="movie" valuePropName="checked" noStyle>
          <Checkbox
            checked={imaConfigs && imaConfigs.movie}
            disabled={imaConfigs && !imaConfigs.is_enable}
          >
            Enable ads for Movies
          </Checkbox>
        </Form.Item>
        <br />
        <Form.Item name="tv_show" valuePropName="checked" noStyle>
          <Checkbox
            checked={imaConfigs && imaConfigs.tv_show}
            disabled={imaConfigs && !imaConfigs.is_enable}
          >
            Enable ads for TV Shows
          </Checkbox>
        </Form.Item>
        <div>
          <h3 className="google-ima-title">Packages wich including ads</h3>

          {packages.map((item) => (
            <div>
              <Checkbox
                name={item.id}
                defaultChecked={item.has_ads}
                disabled={imaConfigs && !imaConfigs.is_enable}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setCheckedPackage({ ...item, checked });
                }}
              >
                {" "}
                {item.name}({item.price} {currency} {item.schedule})
              </Checkbox>
            </div>
          ))}
          <br />
        </div>
        <Form.Item>
          <div className="google-ima_save-btn">
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
