const CONSTANTS = {
    JWT: "JWT",
    REMEMBER_ME: "REMEMBER_ME",

    APP_NAME: window.APP_NAME || "S ADMIN",
    API_HOST: window.API_URL || "https://apismarters.tvplayers.net",

    APP_COLOR: "black",
    LINK_COLOR: "white",
};

export default CONSTANTS;
